<template>
  <div>
    <h5 class="pl-1 font-weight-bold my-2">CREW ACTIVITIES </h5>
    <table class="table table-sm table-bordered small">
      <thead>
        <tr>
          <th rowspan="2" class="align-middle text-center" style="width: 7%">Act. Code</th>
          <th rowspan="2" class="align-middle text-center"  style="width: 7%">Rank / Position</th>
          <th colspan="3" class="align-middle text-center"> Vessel/Office</th>
          <th colspan="3" class="align-middle text-center">Start/Embarkation</th>
          <th colspan="4" class="align-middle text-center">End/Disembarkation</th>
          <th rowspan="2" class="align-middle text-center">Period</th>
          <th rowspan="2" class="align-middle text-center">CER</th>
        </tr>
        <tr>
          <th>Name</th>
          <th class="text-center">Type</th>
          <th class="text-center">Flag</th>
          <th class="text-center">Date</th>
          <th class="text-center">Status</th>
          <th class="text-center">Port</th>
          <th class="text-center">Date</th>
          <th class="text-center">Status</th>
          <th class="text-center">Port</th>
          <th class="text-center">Reason</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(activity,index) in crewActivities">
          <td class="text-center" style="white-space: nowrap">{{activity.activityCode ? activity.activityCode.toUpperCase() : ''}}</td>
          <td class="text-center" style="white-space: nowrap">
            <template  v-if="activity.rankName">
                <span :title="`Onboard as ${activity.rankName}`">
               {{activity.rankName}}
             </span>
              <template v-if="activity.training_rank_id">
                                <span :title="`Training for ${activity.trainingRankName}`">
                                  <hr class="m-0 border-white"/>
                                  <small
                                    class="text-primary font-weight-bolder">T-{{
                                      activity.trainingRankAlias
                                    }}</small>
                                </span>
              </template>
            </template>
            <template v-else>
              {{activity.assignmentPositionName}}
            </template>
          </td>
          <td style="white-space: nowrap">
            <span v-if="activity.vesselName" :title="activity.vesselTitle">
                {{ activity.vesselName.toUpperCase() }}
            </span>
            <span v-else :title="activity.companyOfficeName">
              {{ activity.companyOfficeAlias ? activity.companyOfficeAlias.toUpperCase() : '' }}
            </span>
          </td>
          <td style="white-space: nowrap">
            {{activity.vesselType ? activity.vesselType.toUpperCase() : ''}}
          </td>
          <td style="white-space: nowrap">
            {{activity.vesselFlag ? activity.vesselFlag.toUpperCase() : ''}}
          </td>
          <td class="text-center" :title="activity.start_date">
            {{ activity.start_date }}
          </td>
          <td class="text-center" :title="activity.startStatusName">
            {{ activity.startStatusCode.toUpperCase() }}
          </td>

          <td class="text-center" :title="activity.startPortName">
            {{ activity.startPortCode.toUpperCase() }}
          </td>
          <td class="text-center" :title="activity.end_date">
            {{ activity.end_date }}
          </td>
          <td class="text-center" :title="activity.endStatusName">
            {{ activity.endStatusCode.toUpperCase() }}
          </td>

          <td class="text-center" :title="activity.endPortName">
            {{ activity.endPortCode.toUpperCase() }}
          </td>

          <td class="text-center" :title="activity.endReasonName">
            {{ activity.endReasonCode.toUpperCase() }}
          </td>
          <td class="text-center">
            {{ activity.period.toUpperCase() }}
          </td>
          <td class="text-center">
            {{ activity.cers }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CrewActivity",
  computed:{
    ...mapGetters(['crewActivities'])
  },
  async created() {
    const params={
      cms_id:this.$route.params.id
    }
    await this.getCrewActivities(params)
  },
  methods:{
    ...mapActions(['getCrewActivities'])
  }
}
</script>

<style scoped>

</style>
